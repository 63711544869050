import { postChangeLanguage } from '@api/POST_ChangeLanguage';
import { useAuth } from '@context/AuthContext';
import { CompanyContext } from '@context/CompanyContext';
import { useNavigationConfig } from '@core/hooks/useNavigationConfig';
import { getGuardedNavigation } from '@core/utils/getGuardedNavigation';
import { localStorageService } from '@core/utils/localStorage';
import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import { DataMeInterface, LanguageType } from '@interface/UserInterface';
import {
  ColorDark,
  ColorLight,
  ColorRed,
  Navbar,
  NavbarCompanyDropdown,
  NavbarUserDropdown,
  Sidebar,
  useClickOutside
} from '@uangcermat/uikit-web';
import { useI18n } from 'core/hooks/useI18n';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import pkgJson from '../../package.json';

// FIXME: styled should be fixed at uiKit
const NavbarContainerStyled = styled.div`
  ul {
    z-index: 9;
    top: 3.7rem !important;
  }
  //FIXME: styled should be fixed at uiKit when apps only set to one company
  > div {
    div[data-test-id='navbarCompany'] {
      margin-left: 16px;
      /* button {
        &:hover {
          background-color: ${ColorDark.blackCoral};
          border: ${ColorDark.blackCoral};
          div > p {
            color: #ffffff !important;
          }
        }
      } */
    }
    > div:nth-child(2) > div:first-child {
      display: none;
    }
  }
`;

const companyImgUrl = '/images/navbar-logo.svg';

const appData = {
  appName: process.env.APP_NAME,
  appVersion: pkgJson.version
};

const LayoutStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const SidebarContainerStyled = styled.div`
  min-width: 208px !important;
  // TODO: move this styling to UI kit
  li > div > p {
    font-size: 14px;
    line-height: 16px;
  }
`;

const ContentContainerStyled = styled.div`
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
`;

const initialUserData: NavbarUserDropdown = {
  name: '',
  email: '',
  avatarUrl: '',
  isShowAccount: false,
  isHoverable: true,
  onClickProfile: () => undefined,
  onClickLogout: () => undefined,
  onClickEditProfile: () => undefined
};

const initialCompanyDropdown: NavbarCompanyDropdown = {
  onClickBranch: () => undefined,
  companyName: '',
  companyBranchRefElm: null,
  logoUrl: '',
  listCompany: [],
  isShowBranch: false,
  activeColor: ColorRed.deepCarmine,
  hoverColor: '',
  hoverItemColor: ColorLight.whiteSmoke,
  onClickBranchSwitcher: () => undefined,
  isBranchLogoSquare: true
};

type SidebarMapType = {
  [key: string]: string;
};

export default function DashboardLayout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation(['common']);
  const { logout, userAuth, setUserAuth, activeCompany, isResetCompany, setIsResetCompany } =
    useAuth();
  const { setCompany } = useContext(CompanyContext);

  const { navigationConfig } = useNavigationConfig();

  const router = useRouter();

  const [activeId, setActiveId] = useState('');
  const [companyData, setCompanyData] = useState<NavbarCompanyDropdown>(initialCompanyDropdown);
  const { changeLanguage } = useI18n();
  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowBranch, setIsShowBranch] = useState<boolean>(false);
  const [isHideSidebarAndCompany, setIsHideSidebarAndCompany] = useState<boolean>(false);

  const wrapperRef = useRef(null);
  const companyBranchSwitcherRef = useRef(null);

  const sidebarActiveIdMapper: SidebarMapType = {
    '/activity/report': 'activityReport.view',
    '/accounts/users': 'users.view',
    '/assets/assets-list': 'inspectionAsset.view',
    '/inspection/records': 'inspectionRecord.view',
    '/inspection/forms': 'inspectionForm.view'
  };

  useClickOutside({
    ref: wrapperRef,
    cb: () => {
      setIsOpen(false);
    }
  });

  useClickOutside({
    ref: companyBranchSwitcherRef,
    cb: () => {
      setIsShowBranch(false);
    }
  });
  const currentActiveCompany = localStorageService.getActiveCompany('active_company') ?? '';

  const handleChangeLanguage = async (to: LanguageType) => {
    await postChangeLanguage({ lang: to });
    await syncRefreshToken();
    changeLanguage(to);
    window.location.reload();
  };
  const [userData, setUserData] = useState<NavbarUserDropdown>({
    ...initialUserData,
    isHoverable: true,
    onClickLogout: logout
  });

  useEffect(() => {
    changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const companyId = userAuth?.companies.find(
      (company) => company.id === currentActiveCompany
    )?.id;

    if (companyId) {
      setCompany({
        id: companyId
      });
    }
    return () => {
      setCompany(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, currentActiveCompany, setCompany]);

  const routeActivityReport = '/activity-report';

  const isHideSidebarCompany = (pathname: string): boolean => {
    return ['/switch-company'].includes(pathname);
  };

  useEffect(() => {
    setIsShowBranch(false);
    setIsHideSidebarAndCompany(isHideSidebarCompany(router.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const setActiveCompanyByAuth = async () => {
      if (activeCompany && userAuth) {
        const { id, name, logo } = activeCompany;
        setCompanyData({
          ...companyData,
          companyName: name,
          logoUrl: logo,
          isClickSwitcherNoBranchEnabled: userAuth.companies.length > 1 ? true : false,
          companyBranchRefElm: companyBranchSwitcherRef,
          switchCompanyLabel: t('common:switchCompany'),
          onClickSwitchCompany: () => router.push('/switch-company'),
          hoverColor: userAuth.companies.length > 1 ? ColorRed.deepCarmine : '',
          hoverItemColor: userAuth.companies.length > 1 ? ColorRed.deepCarmine : '',
          activeColor: ColorRed.deepCarmine,
          listCompany: [],
          isHoverable: false
        });
        localStorageService.setActiveCompany('active_company', id ?? '');
      }
    };
    if (!companyData.companyName && !companyData.logoUrl) {
      setActiveCompanyByAuth();
    } else if (isResetCompany && activeCompany && activeCompany.id) {
      setCompanyData({
        ...companyData,
        companyName: activeCompany?.name ?? '',
        logoUrl: activeCompany?.logo ?? '',
        listCompany: []
      });
      localStorageService.setActiveCompany('active_company', activeCompany.id ?? '');
      setIsResetCompany(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData, activeCompany, isResetCompany]);

  const setNavbarData = useCallback(
    (data: DataMeInterface) => {
      setUserData({
        ...userData,
        hoverColor: ColorRed.deepCarmine,
        hoverItemColor: ColorLight.whiteSmoke,
        email: data.email || '',
        name: data.name || '',
        avatarUrl: data?.profilePicture || '',
        onClickLogout: logout
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout, setUserAuth, userAuth, userData.isShowAccount]
  );

  useEffect(() => {
    if (userAuth) {
      setNavbarData(userAuth);
    }
  }, [setNavbarData, userAuth]);

  useEffect(() => {
    setActiveId(sidebarActiveIdMapper[router.route]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveId]);

  return (
    <>
      <LayoutStyled>
        <NavbarContainerStyled>
          {/* FIXME: navbar company logo style */}
          <Navbar
            appLogoUrl={companyImgUrl}
            onClickLogo={() => {
              router.push(routeActivityReport);
              setActiveId(sidebarActiveIdMapper[routeActivityReport]);
            }}
            user={{
              ...userData,
              userRefElm: wrapperRef,
              isShowAccount: isOpen,
              onClickProfile: () => {
                setIsOpen((prev) => !prev);
              },
              onClickEditProfile: () => {
                router.push('/profile');
                setIsOpen((prev) => !prev);
              }
            }}
            languageSwitcher={{
              activeLanguage: currentLanguage,
              isOutsideLanguageSwitcher: true,
              hoverColor: ColorRed.deepCarmine,
              onClickEnglish: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'en'
                  });
                }
                await handleChangeLanguage('en');
              },
              onClickBahasa: async () => {
                if (userAuth) {
                  setUserAuth({
                    ...userAuth,
                    lang: 'id'
                  });
                }
                await handleChangeLanguage('id');
              }
            }}
            company={{
              ...companyData,
              isShowBranch: isShowBranch,
              onClickBranchSwitcher: () => setIsShowBranch((prev) => !prev),
              onClickBranch: () => undefined
            }}
            isHideCompany={isHideSidebarAndCompany}
            bgColor={ColorRed.americanRed}
          />
        </NavbarContainerStyled>
        <MainStyled>
          {!isHideSidebarAndCompany && (
            <SidebarContainerStyled>
              <Sidebar
                navigationConfig={getGuardedNavigation({
                  navConfig: navigationConfig,
                  userPermission: userAuth?.permissions ?? []
                })}
                onClickNav={(nav) => {
                  setActiveId(nav.id);
                  if (nav.navLink) {
                    router.push(nav.navLink);
                  }
                }}
                activeId={activeId}
                appName={appData.appName}
                appVersion={appData.appVersion}
              />
            </SidebarContainerStyled>
          )}
          <ContentContainerStyled>{children}</ContentContainerStyled>
        </MainStyled>
      </LayoutStyled>
    </>
  );
}
