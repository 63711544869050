import { localStorageService } from '@core/utils/localStorage';
import {
  EnglishActivityDetail,
  EnglishActivityReport,
  EnglishAssetsList,
  EnglishCommon,
  EnglishInspectionForm,
  EnglishInspectionRecord,
  EnglishLoginPage,
  EnglishMenu,
  EnglishProfile,
  EnglishUserDetail,
  EnglishUserList
} from '@i18n/locales/en';
import {
  BahasaActivityDetail,
  BahasaActivityReport,
  BahasaAssetsList,
  BahasaCommon,
  BahasaInspectionForm,
  BahasaInspectionRecord,
  BahasaLoginPage,
  BahasaMenu,
  BahasaProfile,
  BahasaUserDetail,
  BahasaUserList
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    common: EnglishCommon,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    activityReport: EnglishActivityReport,
    activityDetail: EnglishActivityDetail,
    userList: EnglishUserList,
    userDetail: EnglishUserDetail,
    profile: EnglishProfile,
    inspectionRecord: EnglishInspectionRecord,
    inspectionForm: EnglishInspectionForm,
    assetsList: EnglishAssetsList
  };

  const bahasaResources = {
    common: BahasaCommon,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    activityReport: BahasaActivityReport,
    activityDetail: BahasaActivityDetail,
    userList: BahasaUserList,
    userDetail: BahasaUserDetail,
    profile: BahasaProfile,
    inspectionRecord: BahasaInspectionRecord,
    inspectionForm: BahasaInspectionForm,
    assetsList: BahasaAssetsList
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: [
      'login',
      'menu',
      'activityReport',
      'activityDetail',
      'userList',
      'userDetail',
      'common',
      'inspectionRecord',
      'inspectionForm',
      'assetsList'
    ]
  };

  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};
