import { NavigationMenuInterface } from '@uangcermat/uikit-web';
import { useTranslation } from 'react-i18next';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const navigationConfig: Array<NavigationMenuInterface> = [
    {
      id: 'activityReport',
      icon: 'calendarcheckin',
      title: t('menu:activities'),
      children: [
        {
          id: 'activityReport.view',
          title: t('menu:activityReport'),
          navLink: '/activity/report'
        }
      ]
    },
    {
      id: 'inspectionRecord',
      icon: 'noteSearch',
      title: t('menu:inspection'),
      children: [
        {
          id: 'inspectionRecord.view',
          title: t('menu:inspectionRecords'),
          navLink: '/inspection/records'
        },
        {
          id: 'inspectionForm.view',
          title: t('menu:inspectionForms'),
          navLink: '/inspection/forms'
        }
      ]
    },
    {
      id: 'inspectionAsset',
      icon: 'noteList',
      title: t('menu:asset'),
      children: [
        {
          id: 'inspectionAsset.view',
          title: t('menu:assetList'),
          navLink: '/assets/assets-list'
        }
      ]
    },
    {
      id: 'users',
      icon: 'user',
      title: t('menu:accounts'),
      children: [
        {
          id: 'users.view',
          title: t('menu:userList'),
          navLink: '/accounts/users?page=1&sort=asc&sortBy=id'
        }
      ]
    }
  ];

  return { navigationConfig };
};
