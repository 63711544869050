import { NavigationMenuInterface } from '@uangcermat/uikit-web/build/src/blocks/Sidebar/Sidebar.type';

export const getGuardedNavigation = ({
  userPermission,
  navConfig
}: {
  userPermission: Array<string>;
  navConfig: Array<NavigationMenuInterface>;
}) => {
  const CUSTOM_PERMISSION = ['inspectionForm.view', 'inspectionForm.update'];
  const finalNav: Array<NavigationMenuInterface> = [];
  const userPermissionTmp: Record<string, Array<string>> = {};

  userPermission.map((nav) => {
    const menu = nav.split('.');
    const isValidMenu = ['workforce', 'assets', 'inspection'].includes(menu[0]);
    if (isValidMenu && userPermissionTmp[menu[1]] === undefined) {
      userPermissionTmp[menu[1]] = [`${menu[1]}.${menu[2]}`];
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (
        CUSTOM_PERMISSION.includes(`${menu[1]}.${menu[2]}`) &&
        userPermissionTmp['inspectionRecord'] === undefined
      ) {
        userPermissionTmp['inspectionRecord'] = [`${menu[1]}.${menu[2]}`];
      }
    } else {
      if (userPermissionTmp[menu[1]]) {
        userPermissionTmp[menu[1]] = [...userPermissionTmp[menu[1]], `${menu[1]}.${menu[2]}`];
        if (CUSTOM_PERMISSION.includes(`${menu[1]}.${menu[2]}`)) {
          userPermissionTmp.inspectionRecord = [
            ...userPermissionTmp['inspectionRecord'],
            `${menu[1]}.${menu[2]}`
          ];
        }
      }
    }
  });
  navConfig.map((menu) => {
    if (userPermissionTmp[menu.id]) {
      const sub: Array<NavigationMenuInterface> = [];
      if (menu?.children) {
        menu.children.map((submenu) => {
          if (userPermissionTmp[menu.id]?.includes(submenu.id)) {
            sub.push(submenu);
          }
        });
      }
      if (sub.length) finalNav.push({ ...menu, children: sub });
    }
  });
  return finalNav;
};
