import { ChangeLanguageInterface } from '@interface/UserInterface';
import { AxiosError, AxiosResponse } from 'axios';

import { axiosAuthInstance } from '../core/axios/axios.authConfig';
import { BaseResponseInterface } from './../interface/BaseResponseInterface';

export const postChangeLanguage = async (
  body: ChangeLanguageInterface
): Promise<BaseResponseInterface> => {
  return axiosAuthInstance
    .post(`/users/language?lang=${body.lang}`)
    .then((res: AxiosResponse<BaseResponseInterface>) => res.data)
    .catch((error: AxiosError) => {
      throw new Error(error?.message);
    });
};
